import React, { useContext, createContext, PropsWithChildren } from "react";
import { ID } from "../api/Types";
import {
  GetCurrentUserDTO,
  GetCurrentUserQueryParams,
} from "../api/Projects/queries/project-users.queries";
import { ProjectService } from "../api/Projects/ProjectsService";
import { useQuery } from "@tanstack/react-query";

export type UserContextType = {
  isCurrentUserAdmin: boolean;
  userId: ID | null;
  avatarUrl: string | null;
  displayName: string | null;
  email: string | null;
  geographicalRegionCode: string | null;
  currencyCode: string | null;
  team: { id: ID; name: string } | null;
};

const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUserContext = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};

export const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { isLoading, data } = useQuery<
    GetCurrentUserDTO,
    GetCurrentUserQueryParams
  >({
    queryKey: ["currentUser"],
    queryFn: async () => ProjectService.queryCurrentUser(),
  });

  let value: UserContextType = {
    isCurrentUserAdmin: false,
    userId: null,
    avatarUrl: null,
    displayName: null,
    email: null,
    geographicalRegionCode: null,
    currencyCode: null,
    team: null,
  };

  if (data?.me) {
    const {
      geographicalRegion,
      currencyCode,
      id: userId,
      avatarUrl,
      displayName,
      email,
      adminOn: isCurrentUserAdmin,
      company,
    } = data.me;

    value = {
      isCurrentUserAdmin,
      userId,
      avatarUrl,
      displayName,
      email,
      geographicalRegionCode: geographicalRegion.code,
      currencyCode,
      team: company,
    };
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
