import { gql } from "graphql-request";
import { CollectionDTO, ID } from "../../Types";

export const GET_PROJECT_USERS_QUERY = gql`
  query GetProjectUserQuery($projectId: ID!, $page: Int, $pageSize: Int) {
    projectUsers(projectId: $projectId, page: $page, pageSize: $pageSize) {
      collection {
        projectId
        userId
        isOwner
        inProjectTeam
        manuallyUpdated
        manuallyDeleted
        user {
          id
          firstName
          lastName
          displayName
          email
          avatarUrl
        }
        role
        updatedAt
      }
      errors {
        title
        message
      }

      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

export type GetProjectUserDTO = {
  projectId: ID;
  userId: ID;
  isOwner: boolean;
  inProjectTeam: boolean;
  manuallyUpdated: boolean;
  manuallyDeleted: boolean;
  user: {
    id: ID;
    firstName: string;
    lastName: string;
    displayName: string;
    email: string;
    avatarUrl: string;
  };
  role: string;
  updatedAt: string;
};

export type GetProjectUsersCollectionDTO = CollectionDTO<
  GetProjectUserDTO,
  "projectUsers"
>;

export type GetProjectUsersQueryParams = {
  projectId: ID;
  page: number;
  pageSize: number;
};

export const GET_ENTITY_INVITATIONS_QUERY = gql`
  query GetEntityInvitations($itemId: ID!, $status: Int) {
    entityInvitations(itemType: Project, itemId: $itemId, status: $status) {
      collection {
        id
        email
        invitee {
          id
          email
          avatarUrl
          displayName
        }
        invitedById
        invitedBy {
          id
          email
        }
        status
        instanceParameters
      }
      errors {
        title
        message
      }
    }
  }
`;

export type GetEntityInvitationsDTO = {
  id: ID;
  email: string;
  invitee?: {
    id: ID;
    email: string;
    avatarUrl: string;
    displayName: string;
  };
  invitedById: ID;
  invitedBy: {
    id: ID;
    email: string;
  };
  status: number;
  instanceParameters?: {
    [key: string]: string | null | undefined;
  };
};

export type GetEntityInvitationsCollectionDTO = CollectionDTO<
  GetEntityInvitationsDTO,
  "entityInvitations"
>;

export type GetEntityInvitationsQueryParams = {
  itemId: ID;
  status: number;
};
