import { gql } from "graphql-request";
import { ProjectSortBy } from "../Types";
import { ID, PaginatedCollectionDTO, SortDirection } from "../../Types";

export const GET_PROJECTS_QUERY = gql`
  query GetProjectsQuery(
    $page: Int
    $pageSize: Int
    $sortBy: ProjectsSortBy
    $sortDirection: SortDirection
    $search: String
    $createdAtFrom: ISO8601DateTime
    $createdAtTo: ISO8601DateTime
    $archived: Boolean
    $predefinedFilter: ProjectsPredefinedFilter
  ) {
    projects(
      page: $page
      pageSize: $pageSize
      sortBy: $sortBy
      sortDirection: $sortDirection
      search: $search
      createdAtFrom: $createdAtFrom
      createdAtTo: $createdAtTo
      archived: $archived
      predefinedFilter: $predefinedFilter
    ) {
      collection {
        id
        name
        createdAt
        updatedAt
        owner {
          displayName
          avatarUrl
          id
        }
        visibility
        team {
          name
          id
          logoUrl
        }
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
      errors {
        title
        message
      }
    }
  }
`;

export type ProjectsPredefinedFilter = "my_projects" | "editor_access" | null;

export type GetProjectsDTO = {
  id: ID;
  name: string;
  createdAt: string;
  updatedAt: string;
  owner: {
    displayName: string;
    avatarUrl: string;
    id: ID;
  };
  visibility: string;
  team?: {
    name: string;
    id: ID;
    logoUrl: string;
  };
};

export type GetProjectsCollectionDTO = PaginatedCollectionDTO<
  GetProjectsDTO,
  "projects"
>;

export type GetProjectsParams = {
  page: number;
  pageSize: number;
  sortBy: ProjectSortBy;
  sortDirection: SortDirection;
  search: string | null;
  createdAtFrom: string | null;
  createdAtTo: string | null;
  archived: boolean;
  predefinedFilter: ProjectsPredefinedFilter;
};
