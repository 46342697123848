import { QueryClient, useMutation } from "@tanstack/react-query";

export const useMutationGQL = <TResult, TParams extends any[]>(
  keyName: string,
  request: (...args: TParams) => Promise<TResult>,
  queryClient?: QueryClient,
) => {
  const mutation = useMutation<TResult, Error, TParams>(
    {
      mutationKey: [keyName],
      mutationFn: async (params: TParams): Promise<TResult> => {
        return request(...params);
      },
    },
    queryClient,
  );

  return {
    mutationFn: mutation.mutateAsync,
    status: mutation.status,
  };
};
