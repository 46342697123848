import { gql } from "graphql-request";
import { CollectionDTO, ID, PaginatedCollectionDTO } from "../../Types";
import {
  ProjectSpecificationResponseDTO,
  SpecificationStatusDTO,
} from "../dtos";
import { SystemUnit } from "../../../components/erb_wrappers/dashboard/projects/types/Types";

export const GET_PROJECT_QUERY = gql`
  query GetProjectQuery($id: ID!) {
    project(id: $id) {
      item {
        id
        name
        hasBuyableItems
        description
        createdAt
        visibility
        owner {
          displayName
        }
        salesforce {
          opportunityUrl
        }
        projectLinks {
          id
          url
          label
          deletedAt
          createdAt
          user {
            displayName
            avatarUrl
          }
        }
        projectApplicationCategories {
          id
          createdAt
          updatedAt
          status
          designCategory {
            id
            name
            parent {
              name
            }
          }
        }
        projectQuotes {
          id
          createdAt
          deletedAt
          updatedAt
          name
          checkoutInformation {
            id
            fileUrl
            quoteNumber
            expirationDate
            geographicalRegion {
              code
              exchangeRate {
                currencyCode
                currencySymbol
              }
            }
            placedBy {
              id
              email
              avatarUrl
              displayName
            }
            totals {
              convertedTotal
              convertedSubtotal
              convertedShippingTotal
              convertedTaxTotal
              convertedTaxAmountShipping
            }
          }
        }
      }
      errors {
        title
        message
      }
    }
  }
`;

export type GetProjectQueryParams = { id: number };

export type GetProjectItemApplicationCategoryDTO = {
  id: ID;
  createdAt: string;
  updatedAt: string;
  status: string;
  designCategory: {
    id: ID;
    name: string;
    parent: {
      name: string;
    };
  };
};

export type GetProjectQuoteDTO = {
  id: ID;
  createdAt: string;
  deletedAt: string;
  updatedAt: string;
  name: string;
  checkoutInformation: {
    id: ID;
    fileUrl: string;
    quoteNumber: string;
    expirationDate: string;
    geographicalRegion: {
      code: string;
      exchangeRate: {
        currencyCode: string;
        currencySymbol: string;
      };
    };
    totals: {
      convertedTotal: number;
      convertedSubtotal: number;
      convertedShippingTotal: number;
      convertedTaxTotal: number;
      convertedTaxAmountShipping: number;
    };
    placedBy: {
      id: ID;
      email: string;
      avatarUrl: string;
      displayName: string;
    };
  };
};

export type GetProjectItemDTO = {
  id: ID;
  name: string;
  hasBuyableItems: string;
  description: string;
  createdAt: string;
  visibility: string;
  owner: {
    displayName: string;
  };
  salesforce: {
    opportunityUrl: string | null;
  };
  projectLinks: {
    id: ID;
    url: string;
    label: string;
    deletedAt: string;
    createdAt: string;
    user: {
      displayName: string;
      avatarUrl: string;
    };
  }[];
  projectApplicationCategories: GetProjectItemApplicationCategoryDTO[];
  projectQuotes: GetProjectQuoteDTO[];
};

export type GetProjectDTO = {
  project: {
    item: GetProjectItemDTO | null;
    errors: null | string[];
  };
};

export const GET_PROJECT_VERSIONS_QUERY = gql`
  query GetProjectVersionsQuery($projectId: ID!, $page: Int!, $pageSize: Int!) {
    projectVersions(projectId: $projectId, page: $page, pageSize: $pageSize) {
      collection {
        projectId
        count
        date
        projectId
      }
      metadata {
        currentPage
        totalCount
        totalPages
      }
      errors {
        title
        message
      }
    }
  }
`;

export type GetProjectVersionsDTO = {
  projectId: string;
  count: number;
  date: string;
};

export type GetProjectVersionCollectionDTO = PaginatedCollectionDTO<
  GetProjectVersionsDTO,
  "projectVersions"
>;

export type GetProjectVersionsQueryParams = {
  projectId: ID | null;
  page: number;
  pageSize: number;
};

export const GET_PROJECT_VERSION_CHANGES_QUERY = gql`
  query GetProjectVersionChangesQuery($projectId: ID!, $date: ISO8601Date!) {
    projectVersionChanges(projectId: $projectId, date: $date) {
      collection {
        id
        projectId
        event
        objectSnapshot
        objectChanges
        metadata
        objectUser {
          avatarUrl
          displayName
          id
        }
        objectUserId
        userId
        user {
          id
          email
          displayName
          avatarUrl
        }
        itemId
        itemType
        createdAt
      }
      metadata {
        currentPage
        totalCount
        totalPages
      }
      errors {
        title
        message
      }
    }
  }
`;

export type GetProjectVersionChangesQueryParams = {
  projectId: ID;
  date: string;
};

export type ProjectVersionProjectCommonChanges = {
  objectChanges: {
    created_at: [string | null, string | null];
    deleted_at: [string | null, string | null];
    updated_at: [string | null, string | null];
  };
  objectSnapshot: {
    created_at: string;
    deleted_at: string;
    updated_at: string;
  };
};

export type ProjectVersionProjectDocumentChanges = {
  objectChanges: {
    filename: [string | null, string | null];
    id: [number | null, number | null];
    mime_type: [string | null, string | null];
    project_id: [number | null, number | null];
    s3_resource: [string | null, string | null];
    user_id: [number | null, number | null];
  };
  objectSnapshot: {
    filename: string;
    id: number;
    mime_type: string;
    project_id: number;
    s3_resource: string;
    user_id: number;
  };
};

export type ProjectVersionProjectApplicationCategoryChanges = {
  objectChanges: {
    status?: [SpecificationStatusDTO | null, SpecificationStatusDTO | null];
    template_responses?: [
      ProjectSpecificationResponseDTO,
      ProjectSpecificationResponseDTO,
    ];
    unit_system?: [SystemUnit | null, SystemUnit | null];
    design_category_id?: [ID | null, ID | null];
  };
  objectSnapshot: {
    application_category_template_id: number;
    application_category_id: number;
    unit_system: SystemUnit;
    status: SpecificationStatusDTO;
    template_responses: ProjectSpecificationResponseDTO;
    design_category_id: ID;
  };
};

export type ProjectVersionProjectChanges = {
  objectChanges: {
    visibility: [string | null, string | null];
    name: [string | null, string | null];
    description: [string | null, string | null];
  };
  objectSnapshot: {
    visibility: string;
    name: string;
    description: string;
  };
};

export type ProjectVersionProjectUserChanges = {
  objectChanges: {
    role: [string | null, string | null];
  };
  objectSnapshot: {
    role: string;
  };
};

export type ProjectVersionProjectLinkChanges = {
  objectChanges: {
    url: [string | null, string | null];
    label: [string | null, string | null];
  };
  objectSnapshot: {
    url: string;
    label: string;
  };
};

export type ProjectVersionProjectDesignChanges = {
  objectChanges: {
    design_category_id: [number | null, number | null];
  };
  objectSnapshot: {
    design_category_id: number;
  };
  metadata?: {
    name?: string;
  };
};

export type ProjectVersionProjectPartChanges = {
  objectChanges: {
    design_category_id: [number | null, number | null];
  };
  objectSnapshot: {
    design_category_id: number;
    item_id: ID;
  };
};

export type ProjectVersionBaseChanges = {
  createdAt: string;
  event: "create" | "update" | "destroy";
  id: string;
  itemId: number;
  userId: number;
  user: {
    id: string;
    email: string;
    displayName: string;
    avatarUrl: string;
  };
  objectUser?: {
    id: string;
    email: string;
    displayName: string;
    avatarUrl: string;
  };
  objectUserId?: ID;
  projectId: number;
};

export type ProjectVersionProjectChangesDTO = ProjectVersionBaseChanges &
  ProjectVersionProjectCommonChanges & {
    itemType: "Project";
  } & ProjectVersionProjectChanges;

export type ProjectVersionProjectDocumentChangesDTO =
  ProjectVersionBaseChanges &
    ProjectVersionProjectCommonChanges & {
      itemType: "ProjectDocument";
    } & ProjectVersionProjectDocumentChanges;

export type ProjectVersionProjectApplicationCategoryChangesDTO =
  ProjectVersionBaseChanges &
    ProjectVersionProjectCommonChanges & {
      itemType: "ProjectApplicationCategory";
    } & ProjectVersionProjectApplicationCategoryChanges;

export type ProjectVersionProjectUserChangesDTO = ProjectVersionBaseChanges &
  ProjectVersionProjectCommonChanges & {
    itemType: "ProjectUser";
  } & ProjectVersionProjectUserChanges;

export type ProjectVersionProjectLinkChangesDTO = ProjectVersionBaseChanges &
  ProjectVersionProjectCommonChanges & {
    itemType: "ProjectLink";
  } & ProjectVersionProjectLinkChanges;

export type ProjectVersionProjectDesignChangesDTO = ProjectVersionBaseChanges &
  ProjectVersionProjectCommonChanges & {
    itemType: "ProjectItem";
    objectSnapshot: {
      type: "ProjectDesign";
    };
  } & ProjectVersionProjectDesignChanges;

export type ProjectVersionProjectPartChangesDTO = ProjectVersionBaseChanges &
  ProjectVersionProjectCommonChanges & {
    itemType: "ProjectItem";
    objectSnapshot: {
      type: "ProjectPart";
    };
  } & ProjectVersionProjectPartChanges;

export type ProjectVersionProjectItemChangesDTO =
  | ProjectVersionProjectDesignChangesDTO
  | ProjectVersionProjectPartChangesDTO;

export type ProjectVersionChangesDTO =
  | ProjectVersionProjectChangesDTO
  | ProjectVersionProjectDocumentChangesDTO
  | ProjectVersionProjectApplicationCategoryChangesDTO
  | ProjectVersionProjectUserChangesDTO
  | ProjectVersionProjectLinkChangesDTO
  | ProjectVersionProjectItemChangesDTO;

export type GetProjectVersionChangesParams = {
  projectId: ID;
  projectChangeId: ID;
};

export type GetProjectVersionChangesDTO = CollectionDTO<
  ProjectVersionChangesDTO,
  "projectVersionChanges"
>;

export const GET_APPLICATION_CATEGORIES_QUERY = gql`
  query GetApplicationCategoriesQuery {
    designCategories(
      subcategoriesOnly: true,
      sortBy: name,
      sortDirection: asc
    ) {
      collection {
        id
        name
        code
        parentId
        parent {
          id
          name
          code
        }
      }
      errors {
        title
        message
      }
    }
  }
`;

export type GetApplicationCategoriesDTO = {
  id: ID;
  name: string;
  code: string;
  parentId: ID;
  parent: {
    id: ID;
    name: string;
    code: string;
  };
};

export type GetApplicationCategoriesCollectionDTO = PaginatedCollectionDTO<
  GetApplicationCategoriesDTO,
  "designCategories"
>;
