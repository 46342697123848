import { gql } from "graphql-request";
import { ErrorMetadata, ID } from "../../Types";

export const CREATE_PROJECT_MUTATION = gql`
  mutation CreateProjectMutation($name: String!, $description: String!) {
    projectCreate(
      input: { name: $name, description: $description, designCategoryId: null }
    ) {
      project {
        id
        name
        description
      }
      errors {
        title
        message
      }
    }
  }
`;

export type CreateProjectMutationParams = {
  name: string;
  description: string;
};

export type CreateProjectDTO = {
  projectCreate: {
    project: {
      name: string;
      description: string;
      id: ID;
    };
  };
} & ErrorMetadata;

export const UPDATE_PROJECT_MUTATION = gql`
  mutation UpdateProjectMutation(
    $id: ID!
    $name: String!
    $description: String!
  ) {
    projectUpdate(input: { id: $id, name: $name, description: $description }) {
      project {
        id
      }
      errors {
        title
        message
      }
    }
  }
`;

export type UpdateProjectMutationParams = {
  id: ID;
  name: string;
  description: string;
};

export type UpdateProjectDTO = {
  projectUpdate: {
    project: {
      id: ID;
    };
  };
} & ErrorMetadata;

export const PROJECT_LINK_DELETE = gql`
  mutation ProjectLinkDelete($id: ID!) {
    projectLinkDelete(input: { id: $id }) {
      projectLink {
        id
      }
      errors {
        title
        message
      }
    }
  }
`;

export const PROJECT_APPLICATION_CATEGORY_DELETE = gql`
  mutation ProjectApplicationCategoryDelete($id: ID!) {
    projectApplicationCategoryDelete(input: { id: $id }) {
      projectApplicationCategory {
        id
      }
      errors {
        title
        message
      }
    }
  }
`;

export const PROJECT_LINK_CREATE = gql`
  mutation ProjectLinkCreate($projectId: ID!, $url: String!, $label: String) {
    projectLinkCreate(
      input: { projectId: $projectId, url: $url, label: $label }
    ) {
      projectLink {
        id
        projectId
        userId
        url
        label
      }
      errors {
        title
        message
      }
    }
  }
`;

export const PROJECT_APPLICATION_CATEGORY_SAVE = gql`
  mutation ProjectApplicationCategorySave(
    $projectId: ID!
    $designCategoryId: ID!
  ) {
    projectApplicationCategorySave(
      input: { projectId: $projectId, designCategoryId: $designCategoryId }
    ) {
      projectApplicationCategory {
        id
        designCategory {
          id
          name
        }

        designCategory {
          id
          name
        }
      }
      errors {
        title
        message
      }
    }
  }
`;

export const DELETE_PROJECT_QUOTE_MUTATION = gql`
  mutation DeleteProjectQuoteMutation($id: ID!) {
    projectQuoteDelete(input: { id: $id }) {
      projectQuote {
        id
      }
      errors {
        title
        message
      }
    }
  }
`;

export type DeleteProjectQuoteMutationParams = {
  id: ID;
};

export type DeleteProjectQuoteDTO = {
  projectQuoteDelete: {
    projectQuote: {
      id: ID;
    };
  } & ErrorMetadata;
};

export const ARCHIVE_PROJECT_MUTATION = gql`
  mutation ArchiveProjectMutation($input: ProjectArchiveInput!) {
    projectArchive(input: $input) {
      clientMutationId
      project {
        id
      }
    }
  }
`;

export type ArchiveProjectMutationParams = {
  input: {
    id: ID;
  };
};

export type ArchiveProjectDTO = {
  projectArchive: {
    clientMutationId: string;
    project: {
      id: ID;
    };
  };
};

export const UnarchiveProjectMutation = gql`
  mutation UnarchiveProjectMutation($input: ProjectUnarchiveInput!) {
    projectUnarchive(input: $input) {
      clientMutationId
      project {
        id
      }
    }
  }
`;

export type UnarchiveProjectMutationParams = {
  input: {
    id: ID;
  };
};

export type UnarchiveProjectDTO = {
  projectUnarchive: {
    clientMutationId: string;
    project: {
      id: ID;
    };
  };
};
