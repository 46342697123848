import * as React from "react";

import { cn } from "@/lib/utils";
import { FieldError } from "react-hook-form";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  noMargin?: boolean;
  error?: FieldError | undefined;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, noMargin, label, required, type, disabled, ...props }, ref) => {
    return (
      <div
        className={cn({
          "mb-xl": !noMargin,
        })}
      >
        {label && (
          <label className="text-sm-medium text-text-secondary mb-sm">
            {label}
            {required && <span className="text-error-600 ml-xs">*</span>}
          </label>
        )}
        <input
          type={type}
          required={required}
          disabled={disabled}
          className={cn(
            "tw-input flex p-lg text-md-regular !outline-none rounded-xs appearance-none bg-white border border-solid border-primary focus:border-brand focus:shadow-custom-hover text-gray-900 w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white",
            "disabled:opacity-50 disabled:cursor-not-allowed",
            className,
          )}
          ref={ref}
          {...props}
        />
        {props.error && (
          <p className="text-error-600 text-sm-regular mt-xs">
            {props.error.message}
          </p>
        )}
      </div>
    );
  },
);
Input.displayName = "Input";

export { Input };
