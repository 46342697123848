import { gql } from "graphql-request";
import {
  ErrorMetadata,
  ID,
  PaginatedCollectionDTO,
  PaginationMetadata,
} from "../../Types";

export const GET_PROJECTS_USERS_QUERY = gql`
  query getProjectsUsersQuery($projectId: ID!, $page: Int, $pageSize: Int) {
    projectUsers(projectId: $projectId, page: $page, pageSize: $pageSize) {
      collection {
        projectId
        role
        user {
          id
          avatarUrl
          email
          displayName
        }
        inProjectTeam
        isOwner
        manuallyUpdated
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
      errors {
        title
        message
      }
    }
  }
`;

export type GetProjectUsersDTO = {
  projectId: ID;
  user: {
    id: ID;
    avatarUrl: string;
    email: string;
    displayName: string;
  };
  role: string;
  inProjectTeam: boolean;
  isOwner: boolean;
  manuallyUpdated: boolean;
};

export type GetProjectsUsersCollectionDTO = PaginatedCollectionDTO<
  GetProjectUsersDTO,
  "projectUsers"
>;

export type GetProjectsUsersQueryParams = {
  projectId: ID;
  page: number;
  pageSize: number;
};

export const GET_PROJECT_USERS_ROLE_FOR_PROJECT_QUERY = gql`
  query GetProjectUsersRoleForProjectQuery($projectId: ID!, $userIds: [ID!]) {
    projectUsers(projectId: $projectId, userIds: $userIds) {
      collection {
        role
        user {
          id
        }
        isOwner
      }
    }
  }
`;

export type GetProjectUsersRoleForProjectQueryParams = {
  projectId: ID;
  userIds: ID[];
};

export type GetProjectUsersRoleForProjectDTO = {
  role: string;
  user: {
    id: ID;
  };
  isOwner: boolean;
};

export type GetProjectUsersRoleForProjectCollectionDTO = PaginatedCollectionDTO<
  GetProjectUsersRoleForProjectDTO,
  "projectUsers"
>;

export const GET_PROJECT_USERS_COUNT_QUERY = gql`
  query getProjectUsersCountQuery($projectId: ID!) {
    projectUsers(projectId: $projectId, pageSize: 1000) {
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
      errors {
        title
        message
      }
    }
  }
`;

export type GetProjectUsersCountDTO = {
  projectUsers: PaginationMetadata & ErrorMetadata;
};

export type GetProjectUsersCountQueryParams = {
  projectId: ID;
};

export const GET_CURRENT_USER_QUERY = gql`
  query GetCurrentUserQuery {
    me {
      adminOn
      avatarUrl
      displayName
      email
      id
      currencyCode
      geographicalRegion {
        code
      }
      company {
        id
        name
      }
    }
  }
`;

export type GetCurrentUserDTO = {
  me: {
    adminOn: boolean;
    avatarUrl: string;
    displayName: string;
    email: string;
    id: ID;
    currencyCode: string;
    geographicalRegion: {
      code: string;
    };
    company: {
      id: ID;
      name: string;
    } | null;
  };
};

export type GetCurrentUserQueryParams = {};
