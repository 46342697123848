import { gql } from "graphql-request";
import { CollectionDTO, ID } from "../../Types";

export const GET_PROJECT_DESIGNS_QUERY = gql`
  query GetProjectDesignsQuery($projectId: ID!) {
    projectItems(projectId: $projectId, itemType: Design) {
      collection {
        id
        quantity
        item
        updatedAt
        obsolete
        projectItemApplicationCategories {
          id
          projectApplicationCategory {
            id
            designCategory {
              id
              name
            }
          }
        }
      }
      errors {
        title
        message
      }
    }
  }
`;

type RegoinalUnitPriceByCurrency = {
  CAD: number;
  EUR: number;
  USD: number;
};

type RegionalUnitPrices = {
  CA?: RegoinalUnitPriceByCurrency;
  EU?: RegoinalUnitPriceByCurrency;
  INTL?: RegoinalUnitPriceByCurrency;
  US?: RegoinalUnitPriceByCurrency;
};

export type GetProjectDesignsDTO = {
  id: ID;
  quantity: number;
  updatedAt: string;
  obsolete: boolean;
  item: {
    id: ID;
    description: string;
    name: string;
    lead_designer_id: number;
    lead_designer: {
      id: number;
      display_name: string;
      avatar_url: string;
    };
    unit_price: number;
    updated_at: string;
    last_graph_version: string;
    image_path: string;
    design_number: string;
    regional_unit_prices: RegionalUnitPrices;
    has_obsolete_parts_cached: boolean;
  };
  projectItemApplicationCategories: {
    id: ID;
    projectApplicationCategory: {
      id: ID;
      designCategory: {
        id: ID;
        name: string;
      };
    };
  }[];
};

export type GetProjectDesignsCollectionDTO = CollectionDTO<
  GetProjectDesignsDTO,
  "projectItems"
>;

export type GetProjectDesignsQueryParams = {
  projectId: ID;
};

export const GET_DESIGNS_BY_NUMBER_QUERY = gql`
  query GetDesignsByNumberQuery($designNumber: String) {
    designs(designNumber: $designNumber) {
      collection {
        id
        name
        description
        imagePath
        designNumber
        designCategory {
          code
          parent {
            code
          }
        }
      }
      errors {
        title
        message
      }
    }
  }
`;

export type GetDesignsByNumberDTO = {
  id: ID;
  name: string;
  description: string;
  imagePath: string;
  designNumber: string;
  designCategory: {
    code: string;
    parent: {
      code: string;
    };
  };
};

export type GetDesignsByNumberCollectionDTO = CollectionDTO<
  GetDesignsByNumberDTO,
  "designs"
>;

export type GetDesignsByNumberParams = {
  designNumber: string;
};
