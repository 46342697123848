import React, { PropsWithChildren } from "react";
import { useToast } from "@/hooks/use-toast";
import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "@/components/ui/toast";
import { cn } from "@/lib/utils";
import { IconCircleCheck, IconCircleX } from "@tabler/icons-react";

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(function ({
        id,
        title,
        description,
        icon,
        action,
        ...props
      }) {
        return (
          <Toast key={id} {...props}>
            <div className="flex gap-xl">
              {icon}
              <div className="grid gap-xs items-center">
                {title && <ToastTitle>{title}</ToastTitle>}
                {description && (
                  <ToastDescription>{description}</ToastDescription>
                )}
              </div>
            </div>
            {action}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}

const IconContainer: React.FC<
  PropsWithChildren<{
    className?: string;
  }>
> = ({ children, className }) => (
  <div
    className={cn(
      "flex items-center justify-center size-4xl rounded-full",
      className,
    )}
  >
    {children}
  </div>
);

export const SuccessIcon = (
  <IconContainer className="bg-success-100">
    <IconCircleCheck className="text-success-600 size-2xl" />
  </IconContainer>
);

export const ErrorIcon = (
  <IconContainer className="bg-error-100">
    <IconCircleX className="text-error-600 size-2xl" />
  </IconContainer>
);
