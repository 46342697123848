import { InfiniteData, UseInfiniteQueryResult } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { ErrorMetadata, PaginationMetadata } from "../api/Types";

export const useInfiniteScroll = <
  TResult,
  TRecord extends keyof TResult,
  Collection,
>(
  K: TRecord & TResult[TRecord] extends PaginationMetadata &
    ErrorMetadata & { collection: Collection[] }
    ? TRecord
    : never,
  query: UseInfiniteQueryResult<InfiniteData<TResult, unknown>, Error>,
  isActive: boolean,
) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const [data, setData] = useState<{ collection: Collection[] }>({
    collection: [],
  });

  useEffect(() => {
    if (!scrollRef?.current) {
      return;
    }

    const threshold = 5; // A small buffer to account for floating-point inaccuracies

    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

    const isAtBottom = scrollHeight - scrollTop - clientHeight <= threshold;

    if (isActive && query.hasNextPage && isAtBottom && !query.isFetching) {
      query.fetchNextPage();
    }
  }, [scrollRef, isActive, query]);

  useEffect(() => {
    if (query.data?.pages) {
      setData({
        collection: [
          ...query.data.pages
            .map(
              (page) => (page?.[K] as { collection: Collection[] })?.collection,
            )
            .flat(),
        ],
      });
    }
  }, [query?.data, isActive]);

  const handleScrollBottom = (e) => {
    if (!e?.target) {
      return;
    }

    const bottom = e.target.scrollHeight - e.target?.scrollTop;
    const isAtBottom = bottom === e.target?.clientHeight;
    if (
      isAtBottom &&
      query.data &&
      query.isLoading === false &&
      query.hasNextPage
    ) {
      query.fetchNextPage();
    }
  };

  const handleClearData = () => {
    setData({ collection: [] });
  };

  return {
    scrollRef,
    handleScrollBottom,
    data,
    handleClearData,
  };
};
