import { gql } from "graphql-request";
import { ErrorMetadata, ID } from "../../Types";

export const SAVE_PROJECT_PART_MUTATION = gql`
  mutation SaveProjectPartMutation(
    $projectId: ID!
    $partId: ID!
    $quantity: Int!
  ) {
    projectItemSave(
      input: {
        projectId: $projectId
        itemType: Part
        itemId: $partId
        quantity: $quantity
      }
    ) {
      projectItem {
        id
      }
      errors {
        title
        message
      }
    }
  }
`;

export type SaveProjectPartMutationDTO = {
  projectItemSave: {
    projectItem: {
      id: string;
    };
  };
} & ErrorMetadata;

export type SaveProjectPartMutationParams = {
  projectId: ID;
  partId: ID;
  quantity: number;
};
