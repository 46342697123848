import { gql } from "graphql-request";
import { ID, PaginatedCollectionDTO } from "../../Types";
import {
  QuestionType,
  SystemUnit,
} from "../../../components/erb_wrappers/dashboard/projects/types/Types";

export const GET_APPLICATION_CATEGORIES_WITH_TEMPLATE_QUERY = gql`
  query GetApplicationCategoriesWithTemplateQuery {
    designCategories(
      subcategoriesOnly: false
      withApplicationCategoryTemplate: true
    ) {
      collection {
        id
        name
        productizedApplication {
          imageUrl
        }
        description
        similarityGroup
      }
      errors {
        title
        message
      }
    }
  }
`;

export type ApplicationCategoryWithTemplaceDTO = {
  id: ID;
  name: string;
  code: string;
  productizedApplication?: {
    imageUrl?: string;
  };
  description?: string;
  similarityGroup?: string;
};

export type GetApplicationCategoriesWithTemplateCollectionDTO =
  PaginatedCollectionDTO<
    ApplicationCategoryWithTemplaceDTO,
    "designCategories"
  >;

export const GET_PROJECT_WITH_APP_CATEGORIES_TEMPLATE_QUERY = gql`
  query GetProjectWithAppCategoriesTemplateQuery($projectId: ID!) {
    project(id: $projectId) {
      item {
        id
        name
        projectApplicationCategories {
          id
          templateResponses
          createdAt
          updatedAt
          status
          unitSystem
          designCategory {
            id
            name
            similarityGroup
            parent {
              id
              name
            }
          }
          applicationCategoryTemplate {
            id
            applicationCategoryTemplateQuestionGroups {
              id
              label
              order
              applicationCategoryTemplateQuestions {
                required
                label
                id
                order
                adminOnly
                applicationCategoryTemplateQuestionType {
                  code
                  questionType
                  instanceParameters
                }
              }
            }
          }
        }
      }
    }
  }
`;

export type GetProjectApplicationCategoryQuestionOptionDTO = {
  value: string;
  label: string;
};

export type GetProjectApplicationCategoryQuestionOptionsDTO = {
  options?: GetProjectApplicationCategoryQuestionOptionDTO[];
};

export type GetProjectApplicationCategoryQuestionDTO = {
  required: boolean;
  label: string;
  id: ID;
  order: number;
  adminOnly: boolean;
  applicationCategoryTemplateQuestionType: {
    code: string;
    questionType: QuestionType;
    instanceParameters: GetProjectApplicationCategoryQuestionOptionsDTO;
  };
};

export type GetProjectApplicationCategoryQuestionGroupDTO = {
  id: ID;
  label: string;
  order: number;
  applicationCategoryTemplateQuestions: GetProjectApplicationCategoryQuestionDTO[];
};

export type GetProjectApplicationCategoryDTO = {
  id: ID;
  templateResponses?: {
    questions?: Record<ID, string | number | string[] | boolean>;
  };
  createdAt: string;
  updatedAt: string;
  status: string;
  unitSystem: SystemUnit;
  designCategory: {
    id: ID;
    name: string;
    similarityGroup?: string;
    parent: {
      id: ID;
      name: string;
    };
  };
  applicationCategoryTemplate: {
    id: ID;
    applicationCategoryTemplateQuestionGroups: GetProjectApplicationCategoryQuestionGroupDTO[];
  };
};

export type GetProjectWithAppCategoriesTemplateObjectDTO = {
  id: ID;
  name: string;
  projectApplicationCategories: GetProjectApplicationCategoryDTO[];
};

export type GetProjectWithAppCategoriesTemplateDTO = {
  project: {
    item: GetProjectWithAppCategoriesTemplateObjectDTO;
  };
};

export type GetProjectWithAppCategoriesTemplateQueryParams = {
  projectId: ID;
};

export const GET_ALL_APPLICATION_CATEGORIES_WITH_SIMILARITY_GROUP_QUERY = gql`
  query GetAllApplicationCategoriesWithSimilarityGroup(
    $similarityGroup: String
  ) {
    designCategories(similarityGroup: $similarityGroup) {
      collection {
        id
        name
      }
      errors {
        title
        message
      }
    }
  }
`;

export type GetAllApplicationCategoriesWithSimilarityGroupDTO = {
  id: ID;
  name: string;
};

export type GetAllApplicationCategoriesWithSimilarityGroupCollectionDTO =
  PaginatedCollectionDTO<
    GetAllApplicationCategoriesWithSimilarityGroupDTO,
    "designCategories"
  >;

export type GetAllApplicationCategoriesWithSimilarityGroupQueryParams = {
  similarityGroup: string;
};
