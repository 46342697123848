import { cn } from "@/lib/utils";
import React from "react";

export type SpinnerProps = {
  className?: string;
};

export const Spinner: React.FC<SpinnerProps> = ({
  className,
}: SpinnerProps) => {
  return (
    <i
      className={cn(
        "fas fa-spinner-third fa-spin i-left size-fit self-center",
        className,
      )}
    />
  );
};
