import { gql } from "graphql-request";
import { ErrorMetadata, ID } from "../../Types";

export enum ProjectUserRoleMutation {
  editor = "editor",
  viewer = "viewer",
}

export const ARCHIVE_PROJECT_USER_INVITATION_MUTATION = gql`
  mutation ArchiveProjectUserInvitationMutation($invitationId: ID!) {
    projectInvitationArchive(input: { id: $invitationId }) {
      projectInvitation {
        id
      }
      errors {
        title
        message
      }
    }
  }
`;

export type ArchiveProjectUserInvitationDTO = {
  projectInvitationArchive: {
    projectInvitation: {
      id: ID;
    };
  } & ErrorMetadata;
};

export type ArchiveProjectUserInvitationMutationParams = {
  invitationId: ID;
};

export const INVITE_PROJECT_USER_MUTATION = gql`
  mutation InviteProjectUserMutation(
    $projectId: ID!
    $email: String!
    $role: ProjectUserRoleMutation!
  ) {
    projectUserInvite(
      input: { projectId: $projectId, email: $email, role: $role }
    ) {
      projectInvitation {
        id
      }
      errors {
        title
        message
      }
    }
  }
`;

export type InviteProjectUserDTO = {
  projectUserInvite: {
    projectInvitation: {
      id: string;
    };
  } & ErrorMetadata;
};

export type InviteProjectUserMutationParams = {
  projectId: ID;
  email: string;
  role: ProjectUserRoleMutation;
};

export const DELETE_PROJECT_USER_MUTATION = gql`
  mutation DeleteProjectUserDeleteMutation($projectId: ID!, $userId: ID!) {
    projectUserDelete(input: { projectId: $projectId, userId: $userId }) {
      projectUser {
        id
      }
      errors {
        title
        message
      }
    }
  }
`;

export type DeleteProjectUserDTO = {
  projectUserDelete: {
    projectUser: {
      id: ID;
    };
  } & ErrorMetadata;
};

export type DeleteProjectUserMutationParams = {
  projectId: ID;
  userId: ID;
};

export const UPDATE_PROJECT_USER_MUTATION = gql`
  mutation UpdateProjectUserMutation(
    $projectId: ID!
    $userId: ID!
    $role: ProjectUserRoleMutation!
  ) {
    projectUserUpdate(
      input: { projectId: $projectId, userId: $userId, role: $role }
    ) {
      projectUser {
        projectId
        userId
        role
      }
      errors {
        title
        message
      }
    }
  }
`;

export type UpdateProjectUserDTO = {
  projectUserUpdate: {
    projectUser: {
      projectId: ID;
      userId: ID;
      role: string;
    };
  } & ErrorMetadata;
};

export type UpdateProjectUserMutationParams = {
  projectId: ID;
  userId: ID;
  role: ProjectUserRoleMutation;
};

export const UPDATE_PROJECT_VISIBILITY_MUTATION = gql`
  mutation UpdateProjectVisibilityMutation(
    $projectId: ID!
    $visibility: String
  ) {
    projectUpdate(input: { id: $projectId, visibility: $visibility }) {
      project {
        id
      }
      errors {
        title
        message
      }
    }
  }
`;

export type UpdateProjectVisibilityDTO = {
  projectUpdate: {
    project: {
      id: ID;
    };
  } & ErrorMetadata;
};

export type UpdateProjectVisibilityMutationParams = {
  projectId: ID;
  visibility: string;
};

export const PROJECT_TRANSFER_OWNERSHIP_MUTATION = gql`
  mutation ProjectTransferOwnershipMutation(
    $projectId: ID!
    $userId: ID!
    $newRole: ProjectUserRoleMutation!
  ) {
    projectTransferOwnership(
      input: { projectId: $projectId, userId: $userId, newRole: $newRole }
    ) {
      project {
        id
        name
        ownerId
        owner {
          id
          email
          firstName
        }
      }
      errors {
        title
        message
      }
    }
  }
`;

export type ProjectTransferOwnershipDTO = {
  projectTransferOwnership: {
    project: {
      id: ID;
      name: string;
      ownerId: ID;
      owner: {
        id: ID;
        email: string;
        firstName: string;
      };
    };
  } & ErrorMetadata;
};

export type ProjectTransferOwnershipMutationParams = {
  projectId: ID;
  userId: ID;
  newRole: ProjectUserRoleMutation;
};
