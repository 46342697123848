import { gql } from "graphql-request";
import { ID, PaginatedCollectionDTO } from "../../Types";

export const GET_PROJECT_DOCUMENTS_QUERY = gql`
  query GetProjectDocumentsQuery($projectId: ID!, $page: Int, $perPage: Int) {
    projectDocuments(projectId: $projectId, page: $page, pageSize: $perPage) {
      collection {
        id
        filename
        s3Resource
        fileUrl
        createdAt
        updatedAt
        label
        user {
          displayName
          avatarUrl
        }
      }
      errors {
        title
        message
      }
    }
  }
`;

export const GET_PROJECT_DOCUMENT_BY_IDS_QUERY = gql`
  query GetProjectDocumentByIdsQuery(
    $projectId: ID!
    $documentIds: [ID!]
    $page: Int
    $perPage: Int
  ) {
    projectDocuments(
      projectId: $projectId
      ids: $documentIds
      page: $page
      pageSize: $perPage
    ) {
      collection {
        id
        filename
        s3Resource
        fileUrl
        mimeType
        createdAt
        updatedAt
        label
        user {
          displayName
          avatarUrl
        }
      }
      errors {
        title
        message
      }
    }
  }
`;

export type GetProjectDocumentByIdsDTO = {
  createdAt: string;
  updatedAt: string;
  filename: string;
  fileUrl: string;
  id: string;
  s3Resource: string;
  label: string;
  mimeType: string;
  user: {
    displayName: string;
    avatarUrl: string;
  };
};

export type ProjectDocumentCollectionByIdsDTO = PaginatedCollectionDTO<
  GetProjectDocumentByIdsDTO,
  "projectDocuments"
>;

export type GetProjectDocumentByIdsQueryParams = {
  projectId: ID;
  documentIds: ID[];
  page?: number;
  perPage?: number;
};
