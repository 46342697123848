import { gql } from "graphql-request";
import { ErrorMetadata, ID } from "../../Types";

export const DELETE_PROJECT_LINK_MUTATION = gql`
  mutation DeleteProjectLinkMutation($linkId: ID!) {
    projectLinkDelete(input: { id: $linkId }) {
      projectLink {
        id
      }
      errors {
        title
        message
      }
    }
  }
`;

export type DeleteProjectLinkMutationDTO = {
  projectLinkDelete: {
    projectLink: {
      id: ID;
    };
  } & ErrorMetadata;
};

export type DeleteProjectLinkMutationParams = {
  linkId: ID;
};

export const CREATE_PROJECT_LINK_MUTATION = gql`
  mutation CreateProjectLinkMutation(
    $projectId: ID!
    $url: String!
    $label: String
  ) {
    projectLinkCreate(
      input: { projectId: $projectId, url: $url, label: $label }
    ) {
      projectLink {
        id
        projectId
        userId
        url
        label
      }
      errors {
        title
        message
      }
    }
  }
`;

export type CreateProjectLinkMutationDTO = {
  projectLinkCreate: {
    projectLink: {
      id: ID;
      projectId: ID;
      userId: ID;
      url: string;
      label: string;
    } & ErrorMetadata;
  };
};

export type CreateProjectLinkMutationParams = {
  projectId: ID;
  url: string;
  label?: string;
};

export const UPDATE_PROJECT_LINK_MUTATION = gql`
  mutation UpdateProjectLinkMutation(
    $linkId: ID!
    $label: String
    $url: String
  ) {
    projectLinkUpdate(input: { id: $linkId, url: $url, label: $label }) {
      projectLink {
        id
      }
      errors {
        title
        message
      }
    }
  }
`;

export type UpdateProjectLinkMutationDTO = {
  projectLinkUpdate: {
    projectLink: {
      id: ID;
    };
  };
} & ErrorMetadata;

export type UpdateProjectLinkMutationParams = {
  linkId: ID;
  label: string | null;
  url: string;
};
