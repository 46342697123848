import { request } from "graphql-request";

import { GRAPHQL_PRIVATE_API } from "../../constants/Vention";
import {
  GET_PROJECTS_QUERY,
  GetProjectsCollectionDTO,
  GetProjectsParams,
} from "./queries/project.queries";
import {
  ProjectFilterCreatedBy,
  ProjectFilterStatus,
  SystemUnit,
} from "../../components/erb_wrappers/dashboard/projects/types/Types";
import {
  GET_CURRENT_USER_QUERY,
  GET_PROJECTS_USERS_QUERY,
  GET_PROJECT_USERS_COUNT_QUERY,
  GET_PROJECT_USERS_ROLE_FOR_PROJECT_QUERY,
  GetCurrentUserDTO,
  GetCurrentUserQueryParams,
  GetProjectUsersCountDTO,
  GetProjectUsersCountQueryParams,
  GetProjectUsersRoleForProjectCollectionDTO,
  GetProjectUsersRoleForProjectQueryParams,
  GetProjectsUsersCollectionDTO,
  GetProjectsUsersQueryParams,
} from "./queries/project-users.queries";
import { ID, SortDirection } from "../Types";
import {
  DELETE_PROJECT_APPLICATION_MUTATION,
  DeleteProjectApplicationMutationDTO,
  DeleteProjectApplicationMutationParams,
  SAVE_PROJECT_APPLICATION_MUTATION,
  SaveProjectApplicationCategoryMutationDTO,
  SaveProjectApplicationCategoryMutationParams,
  UPDATE_PROJECT_SPECIFICATION_MUTATION,
  UPDATE_PROJECT_SPECIFICATION_TYPE_MUTATION,
  UpdateProjectSpecificationMutationDTO,
  UpdateProjectSpecificationMutationParams,
  UpdateProjectSpecificationTypeMutationDTO,
  UpdateProjectSpecificationTypeMutationParams,
} from "./mutations/project-specification.mutations";
import {
  GET_ALL_APPLICATION_CATEGORIES_WITH_SIMILARITY_GROUP_QUERY,
  GET_APPLICATION_CATEGORIES_WITH_TEMPLATE_QUERY,
  GET_PROJECT_WITH_APP_CATEGORIES_TEMPLATE_QUERY,
  GetAllApplicationCategoriesWithSimilarityGroupCollectionDTO,
  GetAllApplicationCategoriesWithSimilarityGroupQueryParams,
  GetApplicationCategoriesWithTemplateCollectionDTO,
  GetProjectWithAppCategoriesTemplateDTO,
  GetProjectWithAppCategoriesTemplateQueryParams,
} from "./queries/project-specification.queries";
import {
  GET_PROJECT_DOCUMENT_BY_IDS_QUERY,
  GetProjectDocumentByIdsQueryParams,
  ProjectDocumentCollectionByIdsDTO,
} from "./queries/project-documents.queries";
import {
  DELETE_PROJECT_DOCUMENT_MUTATION,
  DeleteProjectDocumentMutationDTO,
  DeleteProjectDocumentMutationParams,
  SAVE_PROJECT_DOCUMENT_MUTATION,
  SaveProjectDocumentMutationDTO,
  SaveProjectDocumentMutationParams,
} from "./mutations/project-document.mutations";
import {
  CREATE_PROJECT_MUTATION,
  CreateProjectDTO,
  CreateProjectMutationParams,
  ARCHIVE_PROJECT_MUTATION,
  UPDATE_PROJECT_MUTATION,
  UpdateProjectDTO,
  UpdateProjectMutationParams,
  ArchiveProjectMutationParams,
  ArchiveProjectDTO,
  UnarchiveProjectMutation,
  UnarchiveProjectMutationParams,
  UnarchiveProjectDTO,
  DeleteProjectQuoteDTO,
  DeleteProjectQuoteMutationParams,
  DELETE_PROJECT_QUOTE_MUTATION,
} from "./mutations/project-edit.mutations";
import {
  GET_APPLICATION_CATEGORIES_QUERY,
  GET_PROJECT_QUERY,
  GET_PROJECT_VERSION_CHANGES_QUERY,
  GET_PROJECT_VERSIONS_QUERY,
  GetApplicationCategoriesCollectionDTO,
  GetProjectDTO,
  GetProjectQueryParams,
  GetProjectVersionsQueryParams,
  GetProjectVersionCollectionDTO,
  GetProjectVersionChangesDTO,
  GetProjectVersionChangesQueryParams,
  ProjectVersionChangesDTO,
} from "./queries/project-edit.queries";
import {
  GET_DESIGNS_BY_NUMBER_QUERY,
  GET_PROJECT_DESIGNS_QUERY,
  GetDesignsByNumberCollectionDTO,
  GetDesignsByNumberParams,
  GetProjectDesignsCollectionDTO,
  GetProjectDesignsQueryParams,
} from "./queries/project-designs.queries";
import {
  CREATE_DESIGN_WITHIN_PROJECT_MUTATION,
  CreateDesignWithinProjectMutationDTO,
  CreateDesignWithinProjectMutationParams,
  DELETE_PROJECT_ITEM_MUTATION,
  DeleteProjectItemMutationDTO,
  DeleteProjectItemMutationParams,
  SAVE_PROJECT_DESIGN_MUTATION,
  SaveProjectDesignMutationDTO,
  SaveProjectDesignMutationParams,
} from "./mutations/project-designs.mutations";
import {
  GET_PART_QUERY,
  GET_PARTS_QUERY,
  GET_PARTS_FOR_PROJECT_QUERY,
  GetPartsCollectionDTO,
  GetPartsQueryParams,
  GetPartDTO,
  GetPartQueryParams,
  GetPartsForProjectQueryParams,
  GetPartsForProjectCollectionDTO,
  PartsByIdOrByPartNumberCollectionDTO,
  GET_PARTS_BY_ID_OR_BY_PART_NUMBER_QUERY,
  GetPartsByIdOrByPartNumberParams,
} from "./queries/project-parts.queries";
import {
  CREATE_PROJECT_LINK_MUTATION,
  CreateProjectLinkMutationDTO,
  CreateProjectLinkMutationParams,
  DELETE_PROJECT_LINK_MUTATION,
  DeleteProjectLinkMutationDTO,
  DeleteProjectLinkMutationParams,
  UPDATE_PROJECT_LINK_MUTATION,
  UpdateProjectLinkMutationDTO,
  UpdateProjectLinkMutationParams,
} from "./mutations/project-links.mutations";
import { ProjectSortBy, ProjectsUsersCountDTO } from "./Types";
import {
  SAVE_PROJECT_PART_MUTATION,
  SaveProjectPartMutationDTO,
  SaveProjectPartMutationParams,
} from "./mutations/project-parts.mutations";
import {
  GET_ENTITY_INVITATIONS_QUERY,
  GET_PROJECT_USERS_QUERY,
  GetEntityInvitationsCollectionDTO,
  GetEntityInvitationsQueryParams,
  GetProjectUsersCollectionDTO,
  GetProjectUsersQueryParams,
} from "./queries/project-access.queries";
import {
  ARCHIVE_PROJECT_USER_INVITATION_MUTATION,
  ArchiveProjectUserInvitationDTO,
  ArchiveProjectUserInvitationMutationParams,
  DELETE_PROJECT_USER_MUTATION,
  DeleteProjectUserDTO,
  DeleteProjectUserMutationParams,
  INVITE_PROJECT_USER_MUTATION,
  InviteProjectUserDTO,
  InviteProjectUserMutationParams,
  PROJECT_TRANSFER_OWNERSHIP_MUTATION,
  ProjectTransferOwnershipDTO,
  ProjectTransferOwnershipMutationParams,
  ProjectUserRoleMutation,
  UPDATE_PROJECT_USER_MUTATION,
  UPDATE_PROJECT_VISIBILITY_MUTATION,
  UpdateProjectUserDTO,
  UpdateProjectUserMutationParams,
  UpdateProjectVisibilityDTO,
  UpdateProjectVisibilityMutationParams,
} from "./mutations/project-access.mutations";

export type MutationUpdateProjectSpecificationParams = [
  ID,
  ID,
  any,
  SystemUnit,
];

export type GetProjectVersionsChangesDTO = Record<
  string,
  {
    projectVersionChanges: {
      collection: ProjectVersionChangesDTO[];
    };
  }
>;
export const ProjectService = {
  queryCurrentUser: async () => {
    return request<GetCurrentUserDTO, GetCurrentUserQueryParams>(
      GRAPHQL_PRIVATE_API,
      GET_CURRENT_USER_QUERY,
    );
  },
  queryProjects: async (
    page: number,
    perPage: number,
    sortBy: ProjectSortBy,
    sortDirection: SortDirection,
    search: string | null,
    dateFrom: Date | null,
    dateTo: Date | null,
    status: ProjectFilterStatus | null,
    predefinedFilter: ProjectFilterCreatedBy | null,
  ): Promise<GetProjectsCollectionDTO> => {
    const predefinedFilterValue =
      predefinedFilter === "All" || !predefinedFilter
        ? null
        : predefinedFilter === "Me"
          ? "my_projects"
          : "editor_access";
    return request<GetProjectsCollectionDTO, GetProjectsParams>(
      GRAPHQL_PRIVATE_API,
      GET_PROJECTS_QUERY,
      {
        page,
        pageSize: perPage,
        sortBy,
        sortDirection,
        search,
        createdAtFrom: dateFrom?.toISOString() ?? null,
        createdAtTo: dateTo?.toISOString() ?? null,
        archived: status === "Archived",
        predefinedFilter: predefinedFilterValue,
      },
    );
  },
  queryProjectUsers: async (
    page: number,
    perPage: number,
    projectId: ID,
  ): Promise<GetProjectsUsersCollectionDTO> => {
    return request<GetProjectsUsersCollectionDTO, GetProjectsUsersQueryParams>(
      GRAPHQL_PRIVATE_API,
      GET_PROJECTS_USERS_QUERY,
      {
        projectId,
        page,
        pageSize: perPage,
      },
    );
  },
  queryProjectUsersAll: async (
    projectId: ID,
    page = 1,
    pageSize = 16,
  ): Promise<GetProjectUsersCollectionDTO> => {
    return request<GetProjectUsersCollectionDTO, GetProjectUsersQueryParams>(
      GRAPHQL_PRIVATE_API,
      GET_PROJECT_USERS_QUERY,
      {
        projectId,
        page,
        pageSize,
      },
    );
  },
  queryProjectUsersForPermissions: async (
    projectId: ID,
    userIds: ID[],
  ): Promise<GetProjectUsersRoleForProjectCollectionDTO> => {
    return request<
      GetProjectUsersRoleForProjectCollectionDTO,
      GetProjectUsersRoleForProjectQueryParams
    >(GRAPHQL_PRIVATE_API, GET_PROJECT_USERS_ROLE_FOR_PROJECT_QUERY, {
      projectId,
      userIds,
    });
  },
  queryProjectPendingInvitations: async (projectId: ID) => {
    return request<
      GetEntityInvitationsCollectionDTO,
      GetEntityInvitationsQueryParams
    >(GRAPHQL_PRIVATE_API, GET_ENTITY_INVITATIONS_QUERY, {
      itemId: projectId,
      status: 0,
    });
  },
  queryProjectsUsersCount: async (
    projectIds: ID[],
  ): Promise<ProjectsUsersCountDTO> => {
    const promises = projectIds.map((projectId) => {
      return request<GetProjectUsersCountDTO, GetProjectUsersCountQueryParams>(
        GRAPHQL_PRIVATE_API,
        GET_PROJECT_USERS_COUNT_QUERY,
        {
          projectId,
        },
      );
    });

    const results: GetProjectUsersCountDTO[] = await Promise.all(promises);

    const parsedResults: Record<string, number> = {};

    results.forEach((result, index) => {
      parsedResults[projectIds[index]] =
        result.projectUsers?.metadata?.totalCount ?? 0;
    });

    return parsedResults;
  },
  queryApplicationsCategories:
    async (): Promise<GetApplicationCategoriesWithTemplateCollectionDTO> => {
      return request<GetApplicationCategoriesWithTemplateCollectionDTO>(
        GRAPHQL_PRIVATE_API,
        GET_APPLICATION_CATEGORIES_WITH_TEMPLATE_QUERY,
      );
    },
  queryAllApplicationCategoriesWithSameSimilarityGroup: async (
    similarityGroup: string,
  ): Promise<GetAllApplicationCategoriesWithSimilarityGroupCollectionDTO> => {
    return request<
      GetAllApplicationCategoriesWithSimilarityGroupCollectionDTO,
      GetAllApplicationCategoriesWithSimilarityGroupQueryParams
    >(
      GRAPHQL_PRIVATE_API,
      GET_ALL_APPLICATION_CATEGORIES_WITH_SIMILARITY_GROUP_QUERY,
      {
        similarityGroup,
      },
    );
  },
  queryProjectWithAppCategoriesTemplate: async (
    projectId: ID,
  ): Promise<GetProjectWithAppCategoriesTemplateDTO> => {
    return request<
      GetProjectWithAppCategoriesTemplateDTO,
      GetProjectWithAppCategoriesTemplateQueryParams
    >(GRAPHQL_PRIVATE_API, GET_PROJECT_WITH_APP_CATEGORIES_TEMPLATE_QUERY, {
      projectId,
    });
  },
  queryProjectById: async (id: number): Promise<GetProjectDTO> => {
    return request<GetProjectDTO, GetProjectQueryParams>(
      GRAPHQL_PRIVATE_API,
      GET_PROJECT_QUERY,
      {
        id,
      },
    );
  },
  queryProjectDocumentByIds: async (
    page: number,
    perPage: number,
    projectId: ID,
    projectDocumentIds: ID[],
  ): Promise<ProjectDocumentCollectionByIdsDTO> => {
    return request<
      ProjectDocumentCollectionByIdsDTO,
      GetProjectDocumentByIdsQueryParams
    >(GRAPHQL_PRIVATE_API, GET_PROJECT_DOCUMENT_BY_IDS_QUERY, {
      projectId,
      page,
      perPage,
      documentIds: projectDocumentIds,
    });
  },
  queryProjectVersions: async (
    page: number,
    perPage: number,
    projectId: ID | null,
  ): Promise<GetProjectVersionCollectionDTO> => {
    return request<
      GetProjectVersionCollectionDTO,
      GetProjectVersionsQueryParams
    >(GRAPHQL_PRIVATE_API, GET_PROJECT_VERSIONS_QUERY, {
      projectId,
      page,
      pageSize: perPage,
    });
  },
  queryProjectVersionsChanges: async (
    projectChangesDates: string[] | null,
    projectId: ID,
  ): Promise<GetProjectVersionsChangesDTO> => {
    const promises = projectChangesDates?.map((projectChangeDate) => {
      return request<
        GetProjectVersionChangesDTO,
        GetProjectVersionChangesQueryParams
      >(GRAPHQL_PRIVATE_API, GET_PROJECT_VERSION_CHANGES_QUERY, {
        projectId,
        date: projectChangeDate,
      });
    });

    const results: any[] = await Promise.all(promises ?? []);

    const parsedResults: Record<
      string,
      {
        projectVersionChanges: {
          collection: ProjectVersionChangesDTO[];
        };
      }
    > = {};

    results.forEach((result, index) => {
      parsedResults[projectChangesDates?.[index] ?? ""] = result;
    });

    return parsedResults;
  },
  queryDesignsByProjectId: async (
    projectId: ID,
  ): Promise<GetProjectDesignsCollectionDTO> => {
    return request<
      GetProjectDesignsCollectionDTO,
      GetProjectDesignsQueryParams
    >(GRAPHQL_PRIVATE_API, GET_PROJECT_DESIGNS_QUERY, {
      projectId,
    });
  },
  queryDesignsByNumber: async (
    params: GetDesignsByNumberParams,
  ): Promise<GetDesignsByNumberCollectionDTO> => {
    return request<GetDesignsByNumberCollectionDTO, GetDesignsByNumberParams>(
      GRAPHQL_PRIVATE_API,
      GET_DESIGNS_BY_NUMBER_QUERY,
      params,
    );
  },
  queryParts: async (partIds: ID[]): Promise<GetPartsCollectionDTO> => {
    return request<GetPartsCollectionDTO, GetPartsQueryParams>(
      GRAPHQL_PRIVATE_API,
      GET_PARTS_QUERY,
      {
        partIds,
      },
    );
  },
  queryPart: async (partId: ID): Promise<GetPartDTO> => {
    return request<GetPartDTO, GetPartQueryParams>(
      GRAPHQL_PRIVATE_API,
      GET_PART_QUERY,
      {
        partId,
      },
    );
  },
  queryPartByIdOrByPartNumber: async (
    partIdOrPartNumber: string,
  ): Promise<PartsByIdOrByPartNumberCollectionDTO> => {
    return request<
      PartsByIdOrByPartNumberCollectionDTO,
      GetPartsByIdOrByPartNumberParams
    >(GRAPHQL_PRIVATE_API, GET_PARTS_BY_ID_OR_BY_PART_NUMBER_QUERY, {
      partIdOrPartNumber,
    });
  },
  queryPartsForProject: async (
    page: number,
    perPage: number,
    projectId: ID,
  ): Promise<GetPartsForProjectCollectionDTO> => {
    return request<
      GetPartsForProjectCollectionDTO,
      GetPartsForProjectQueryParams
    >(GRAPHQL_PRIVATE_API, GET_PARTS_FOR_PROJECT_QUERY, {
      projectId,
      page,
      pageSize: perPage,
    });
  },
  queryDesignCategories:
    async (): Promise<GetApplicationCategoriesCollectionDTO> => {
      return request<
        GetApplicationCategoriesCollectionDTO
      >(GRAPHQL_PRIVATE_API, GET_APPLICATION_CATEGORIES_QUERY);
    },
  mutateCreateProject: async (
    name: string,
    description: string,
  ): Promise<CreateProjectDTO> => {
    return request<CreateProjectDTO, CreateProjectMutationParams>(
      GRAPHQL_PRIVATE_API,
      CREATE_PROJECT_MUTATION,
      {
        name,
        description,
      },
    );
  },
  mutateUpdateProject: async (
    id: ID,
    name: string,
    description: string,
  ): Promise<UpdateProjectDTO> => {
    return request<UpdateProjectDTO, UpdateProjectMutationParams>(
      GRAPHQL_PRIVATE_API,
      UPDATE_PROJECT_MUTATION,
      {
        id,
        name,
        description,
      },
    );
  },
  mutateArchiveProject: async (
    projectId: number,
  ): Promise<ArchiveProjectDTO> => {
    return request<ArchiveProjectDTO, ArchiveProjectMutationParams>(
      GRAPHQL_PRIVATE_API,
      ARCHIVE_PROJECT_MUTATION,
      {
        input: { id: projectId },
      },
    );
  },
  mutateUnarchiveProject: async (
    projectId: ID,
  ): Promise<UnarchiveProjectDTO> => {
    return request<UnarchiveProjectDTO, UnarchiveProjectMutationParams>(
      GRAPHQL_PRIVATE_API,
      UnarchiveProjectMutation,
      {
        input: { id: projectId },
      },
    );
  },
  mutateCreateSpecification: async (
    projectId: number,
    applicationCategoryId: number,
  ): Promise<SaveProjectApplicationCategoryMutationDTO> => {
    return request<
      SaveProjectApplicationCategoryMutationDTO,
      SaveProjectApplicationCategoryMutationParams
    >(GRAPHQL_PRIVATE_API, SAVE_PROJECT_APPLICATION_MUTATION, {
      projectId,
      designCategoryId: applicationCategoryId,
    });
  },
  mutateDeleteSpecification: async (
    specificationId: ID,
  ): Promise<DeleteProjectApplicationMutationDTO> => {
    return request<
      DeleteProjectApplicationMutationDTO,
      DeleteProjectApplicationMutationParams
    >(GRAPHQL_PRIVATE_API, DELETE_PROJECT_APPLICATION_MUTATION, {
      designCategoryId: specificationId,
    });
  },
  mutateDeleteProjectDocument: async (
    documentId: ID,
  ): Promise<DeleteProjectDocumentMutationDTO> => {
    return request<
      DeleteProjectDocumentMutationDTO,
      DeleteProjectDocumentMutationParams
    >(GRAPHQL_PRIVATE_API, DELETE_PROJECT_DOCUMENT_MUTATION, {
      documentId,
    });
  },
  mutateDeleteProjectLink: async (
    linkId: ID,
  ): Promise<DeleteProjectLinkMutationDTO> => {
    return request<
      DeleteProjectLinkMutationDTO,
      DeleteProjectLinkMutationParams
    >(GRAPHQL_PRIVATE_API, DELETE_PROJECT_LINK_MUTATION, {
      linkId,
    });
  },
  mutateUpdateProjectLink: async (
    linkId: ID,
    url: string,
    label: string | null,
  ): Promise<UpdateProjectLinkMutationDTO> => {
    return request<
      UpdateProjectLinkMutationDTO,
      UpdateProjectLinkMutationParams
    >(GRAPHQL_PRIVATE_API, UPDATE_PROJECT_LINK_MUTATION, {
      linkId,
      url,
      label,
    });
  },
  mutateSaveDocument: async (
    projectId: string,
    filename: string,
    s3Resource: string,
    mimeType: string,
  ): Promise<SaveProjectDocumentMutationDTO> => {
    return request<
      SaveProjectDocumentMutationDTO,
      SaveProjectDocumentMutationParams
    >(GRAPHQL_PRIVATE_API, SAVE_PROJECT_DOCUMENT_MUTATION, {
      projectId,
      filename,
      s3Resource,
      mimeType,
    });
  },
  mutateUpdateProjectSpecification: async (
    projectId: ID,
    specificationId: ID,
    templateResponsesJSON: any,
    unitSystem: SystemUnit,
  ): Promise<UpdateProjectSpecificationMutationDTO> => {
    return request<
      UpdateProjectSpecificationMutationDTO,
      UpdateProjectSpecificationMutationParams
    >(GRAPHQL_PRIVATE_API, UPDATE_PROJECT_SPECIFICATION_MUTATION, {
      projectId,
      designCategoryId: specificationId,
      templateResponses: templateResponsesJSON,
      unitSystem,
    });
  },
  mutateUpdateProjectSpecificationType: async (
    projectId: ID,
    fromSpecificationId: ID,
    toSpecificationId: ID,
  ): Promise<UpdateProjectSpecificationTypeMutationDTO> => {
    return request<
      UpdateProjectSpecificationTypeMutationDTO,
      UpdateProjectSpecificationTypeMutationParams
    >(GRAPHQL_PRIVATE_API, UPDATE_PROJECT_SPECIFICATION_TYPE_MUTATION, {
      projectId,
      fromDesignCategoryId: fromSpecificationId,
      toDesignCategoryId: toSpecificationId,
    });
  },
  mutateCreateProjectDesign: async ({
    projectId,
    name,
    applicationCategoryCodes,
    description,
    quantity,
  }: CreateDesignWithinProjectMutationParams): Promise<CreateDesignWithinProjectMutationDTO> => {
    return request<
      CreateDesignWithinProjectMutationDTO,
      CreateDesignWithinProjectMutationParams
    >(GRAPHQL_PRIVATE_API, CREATE_DESIGN_WITHIN_PROJECT_MUTATION, {
      projectId,
      name,
      applicationCategoryCodes,
      description,
      quantity,
    });
  },
  mutateSaveProjectDesign: async (
    params: SaveProjectDesignMutationParams,
  ): Promise<SaveProjectDesignMutationDTO> => {
    return request<
      SaveProjectDesignMutationDTO,
      SaveProjectDesignMutationParams
    >(GRAPHQL_PRIVATE_API, SAVE_PROJECT_DESIGN_MUTATION, params);
  },
  mutateSaveProjectPart: async (
    projectId: ID,
    partId: ID,
    quantity: number,
  ): Promise<SaveProjectPartMutationDTO> => {
    return request<SaveProjectPartMutationDTO, SaveProjectPartMutationParams>(
      GRAPHQL_PRIVATE_API,
      SAVE_PROJECT_PART_MUTATION,
      {
        projectId,
        partId,
        quantity,
      },
    );
  },
  mutateDeleteProjectDesignOrPart: async (
    id: ID,
  ): Promise<DeleteProjectItemMutationDTO> => {
    return request<
      DeleteProjectItemMutationDTO,
      DeleteProjectItemMutationParams
    >(GRAPHQL_PRIVATE_API, DELETE_PROJECT_ITEM_MUTATION, {
      id,
    });
  },
  mutateCreateProjectLink: async (
    projectId: ID,
    url: string,
    label?: string,
  ): Promise<CreateProjectLinkMutationDTO> => {
    return request<
      CreateProjectLinkMutationDTO,
      CreateProjectLinkMutationParams
    >(GRAPHQL_PRIVATE_API, CREATE_PROJECT_LINK_MUTATION, {
      projectId,
      url,
      label,
    });
  },
  mutationProjectUserInvite: async (
    projectId: ID,
    email: string,
    role: ProjectUserRoleMutation,
  ): Promise<InviteProjectUserDTO> => {
    return request<InviteProjectUserDTO, InviteProjectUserMutationParams>(
      GRAPHQL_PRIVATE_API,
      INVITE_PROJECT_USER_MUTATION,
      {
        projectId,
        email,
        role,
      },
    );
  },
  mutationProjectUserInviteArchive: async (invitationId: ID) => {
    return request<
      ArchiveProjectUserInvitationDTO,
      ArchiveProjectUserInvitationMutationParams
    >(GRAPHQL_PRIVATE_API, ARCHIVE_PROJECT_USER_INVITATION_MUTATION, {
      invitationId,
    });
  },
  mutationProjectUserDelete: async (projectId: ID, userId: ID) => {
    return request<DeleteProjectUserDTO, DeleteProjectUserMutationParams>(
      GRAPHQL_PRIVATE_API,
      DELETE_PROJECT_USER_MUTATION,
      {
        projectId,
        userId,
      },
    );
  },
  mutationProjectUserUpdate: async (
    projectId: ID,
    userId: ID,
    role: ProjectUserRoleMutation,
  ) => {
    return request<UpdateProjectUserDTO, UpdateProjectUserMutationParams>(
      GRAPHQL_PRIVATE_API,
      UPDATE_PROJECT_USER_MUTATION,
      {
        projectId,
        userId,
        role,
      },
    );
  },
  mutationProjectVisibilityUpdate: async (
    projectId: ID,
    visibility: string,
  ) => {
    return request<
      UpdateProjectVisibilityDTO,
      UpdateProjectVisibilityMutationParams
    >(GRAPHQL_PRIVATE_API, UPDATE_PROJECT_VISIBILITY_MUTATION, {
      projectId,
      visibility,
    });
  },
  mutationDeleteProjectQuote: async (projectQuoteId: ID) => {
    return request<DeleteProjectQuoteDTO, DeleteProjectQuoteMutationParams>(
      GRAPHQL_PRIVATE_API,
      DELETE_PROJECT_QUOTE_MUTATION,
      {
        id: projectQuoteId,
      },
    );
  },
  mutationProjectTransferOwnership: async (
    projectId: ID,
    userId: ID,
    newRole: ProjectUserRoleMutation,
  ) => {
    return request<
      ProjectTransferOwnershipDTO,
      ProjectTransferOwnershipMutationParams
    >(GRAPHQL_PRIVATE_API, PROJECT_TRANSFER_OWNERSHIP_MUTATION, {
      projectId,
      userId,
      newRole,
    });
  },
};
