import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { IconCheck, IconMinus } from "@tabler/icons-react";

import { cn } from "@/lib/utils";
import { cva } from "class-variance-authority";

const checkboxVariants = cva(
  `h-6 w-6 rounded-md border-slate-300 bg-white border-solid items-center justify-center p-0
  focus-visible:outline-none focus-visible:shadow-ring-brand-shadow-xs
  disabled:cursor-not-allowed disabled:bg-slate-50
  disabled:data-[state=checked]:bg-slate-50 disabled:data-[state=checked]:text-slate-300 disabled:data-[state=checked]:border-slate-300
  disabled:data-[state=unchecked]:bg-slate-50 disabled:data-[state=unchecked]:text-slate-300 disabled:data-[state=unchecked]:border-slate-300
  disabled:data-[state=indeterminate]:bg-slate-50 disabled:data-[state=indeterminate]:text-slate-300 disabled:data-[state=indeterminate]:border-slate-300
  enabled:data-[state=checked]:bg-blue-600 enabled:data-[state=checked]:text-white enabled:data-[state=checked]:border-blue-600`,
);

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(checkboxVariants(), className)}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn(
        "flex items-center justify-center text-current disabled:data-[state=unchecked]:visible",
      )}
    >
      {props.checked === "indeterminate" ? (
        <IconMinus className="h-3 w-3" />
      ) : (
        <IconCheck className="h-3 w-3" />
      )}
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
