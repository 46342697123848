import { gql } from "graphql-request";
import { ErrorMetadata, ID } from "../../Types";

export const DELETE_PROJECT_DOCUMENT_MUTATION = gql`
  mutation DeleteProjectDocumentMutation($documentId: ID!) {
    projectDocumentDelete(input: { id: $documentId }) {
      projectDocument {
        id
      }
      errors {
        title
        message
      }
    }
  }
`;

export type DeleteProjectDocumentMutationDTO = {
  projectDocumentDelete: {
    projectDocument: {
      id: string;
    };
  } & ErrorMetadata;
};

export type DeleteProjectDocumentMutationParams = {
  documentId: ID;
};

export const SAVE_PROJECT_DOCUMENT_MUTATION = gql`
  mutation SaveProjectDocumentMutation(
    $projectId: ID!
    $filename: String!
    $s3Resource: String!
    $mimeType: String!
  ) {
    projectDocumentSave(
      input: {
        projectId: $projectId
        filename: $filename
        s3Resource: $s3Resource
        mimeType: $mimeType
      }
    ) {
      projectDocument {
        id
        filename
        s3Resource
      }
      errors {
        title
        message
      }
    }
  }
`;

export type SaveProjectDocumentMutationDTO = {
  projectDocumentSave: {
    projectDocument: {
      id: ID;
      filename: string;
      s3Resource: string;
    };
  } & ErrorMetadata;
};

export type SaveProjectDocumentMutationParams = {
  projectId: ID;
  filename: string;
  s3Resource: string;
  mimeType: string;
};
